/*
 * @Author: JeasonWeb Jeason_zhang888@163.com
 * @Date: 2022-06-17 14:46:24
 * @LastEditors: JeasonWeb Jeason_zhang888@163.com
 * @LastEditTime: 2022-07-16 19:02:12
 * @FilePath: /web/Users/zhanghongtao/Desktop/web/seller-enter-web/src/api/saleContract.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import server from '@/request';

const prefix = '/xiaozi-seller/wireless/contract';

// 合同列表(分页)
export const queryPage = (data = {}) => {
  return server.post(`${prefix}/queryPage`, data);
};

// 统计 采购合同 状态信息
export const queryStateInfo = (data = {}) => {
  return server.post(`${prefix}/queryStateInfo`, data);
};

// 查询合同(根据合同ID)
export const queryDetail = (data = {}) => {
  return server.post(`${prefix}/queryById`, data);
};

// 分页查询操作日志
export const queryLogListById = (data = {}) => {
  return server.post('/xiaozi-seller/wireless/seller/queryLogListById', data);
};

// 合同变更审核
export const editCheckState = (data = {}) => {
  return server.post(`${prefix}/editCheckStateById`, data);
};

// 合同审核
export const editContractState = (data = {}) => {
  return server.post(`${prefix}/editContractStateById`, data);
};

// 供应商详情
export const customerDetail = (data = {}) => {
  return server.post('/xiaozi-seller/wireless/customer/detail', data);
};

// 供应商详情

export const supplierDetail = (data = {}) => {
  return server.post('/xiaozi-seller/wireless/supplier/detail', data);
};

// 物资信息 分页列表(根据协议 || 合同 ID)
export const queryMaterialList = (data = {}) => {
  return server.post(`${prefix}/queryMaterialList`, data);
};

// 获取核算单位详情

export const detailAccountingUnit = (data = {}) => {
  return server.post('/xiaozi-seller/wireless/system/queryAccountingUnitDetail', data);
};

// 销售合同导出
export const exportSaleContract = (params) => {
  return server.post('/xiaozi-seller/wireless/contract/exportContractItem', params, {
    responseType: 'blob',
  });
};
